import React from "react"

import "./location-tile.scss"

class LocationTile extends React.Component {

    render(){

        let float;

        if(this.props.align === 'left')
            float = 'float-left'
        
        if(this.props.align === 'right')
            float = 'float-right'
        
        if(this.props.align === 'center')
            float = 'mx-auto'

        return(
            <div className={`cursor-pointer square mb-6 md:mb-12 lg:mb-16 ${float}`} style={{backgroundImage:`url(${this.props.file})`}}>
                <div className={"hover-top"}>
                    <div className={"content p8 leading-none"}>
                        {this.props.day && <p className={"font-montserrat-regular text-white text-xs md:text-lg lg:text-lg uppercase"}>{this.props.day}</p>}
                        {this.props.month && <p className={"font-montserrat-regular text-white text-xs md:text-lg lg:text-lg uppercase"}>{this.props.month}</p>}
                        {this.props.text && <p className={"font-montserrat-regular text-white text-xxs md:text-base lg:text-lg uppercase"}>{this.props.text}</p>}
                        <p className={"font-montserrat-extra-bold text-white text-xxs md:text-sm lg:text-lg"}>{this.props.title}</p>
                    </div>
                </div>
                <div className={"absolute hover-background"}></div>
            </div>
        )
    }
}

export default LocationTile
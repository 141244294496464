import React from "react"
import { Link } from "gatsby"

import Button from "../button/button"
import Styles from "./featured-video.module.scss"

class FeaturedVideos extends React.Component {
    
    render(){

        const { videos, locale } = this.props

        return(
            <section className={"my-16"}>
                <div className={"container mx-auto md:px-8 lg:px-8"}>
                    <div className={"w-full text-left pl-4 md:pl-0 mb-4"}>
                        <p className={"uppercase text-lg font-formula-light tracking-widest"}>the latest from drop tv</p>
                    </div>
                    <div className={`flex flex-wrap mb-12 md:mb-12 ${Styles.thumbnail}`}>
                        <div className={"mx-auto w-full md:w-1/2 px-4 md:px-0 overflow-hidden"}>
                            <img src={videos[0].video.image.file.url} alt="" className={Styles.thumbnailImage} />
                        </div>
                        <div className={"mx-auto w-full md:w-1/2 px-4 md:px-0"}>
                            <div className={"flex flex-col align-center justify-center w-full h-full md:pl-8 leading-none text-left relative"}>
                                <div className={"py-4"}>
                                    <h1 className={"font-agrandir md:text-3xl lg:text-4xl block"}>{videos[0].video.title}</h1>
                                </div>
                                <div className={"py-4"}>
                                    <Link to={`/${locale}/tv/${videos[0].video.slug}`}>
                                        <Button label={"watch now"} type={"underline"} display={"inline"} />
                                    </Link>
                                </div>
                                <div className={Styles.pattern} />
                            </div>
                        </div>
                    </div>
                    <div className={"flex flex-wrap mb-12"}>
                        
                        <div className={`mx-auto w-full md:w-1/2 pr-4 pl-4 md:pl-0 lg:pr-12 mb-12 md:mb-0 ${Styles.thumbnail}`}>
                            <div className={"hidden md:block py-8"}>
                                <div className={"flex flex-col align-center justify-center w-full h-full pr-8 leading-none text-left relative"}>
                                    <div className={"py-4"}>
                                        <h1 className={"font-agrandir md:text-3xl lg:text-4xl block"}>{videos[1].video.title}</h1>
                                    </div>
                                    <div className={"py-4"}>
                                        <Link to={`/${locale}/tv/${videos[1].video.slug}`}>
                                            <Button label={"watch now"} type={"underline"} display={"inline"} />
                                        </Link>
                                    </div>
                                    <div className={Styles.pattern} />
                                </div>
                            </div>
                            <div className={"overflow-hidden"}>
                                <img src={videos[1].video.image.file.url} alt="" className={Styles.thumbnailImage} />
                            </div>
                            <div className={"py-8 md:hidden"}>
                                <div className={"flex flex-col align-center justify-center w-full h-full pr-0 md:pr-8 leading-none text-left relative"}>
                                    <div className={"py-4"}>
                                        <h1 className={"font-agrandir md:text-3xl lg:text-4xl block"}>{videos[1].video.title}</h1>
                                    </div>
                                    <div className={"py-4"}>
                                        <Link to={`/${locale}/tv/${videos[1].video.slug}`}>
                                            <Button label={"watch now"} type={"underline"} display={"inline"} />
                                        </Link>
                                    </div>
                                    <div className={Styles.pattern} />
                                </div>
                            </div>
                        </div>

                        <div className={`mx-auto w-full md:w-1/2 pl-4 pr-4 md:pr-0 lg:pl-12 ${Styles.thumbnail}`}>
                            <div className={"overflow-hidden"}>
                                <img src={videos[2].video.image.file.url} alt="" className={Styles.thumbnailImage} />
                            </div>
                            <div className={"py-8"}>
                                <div className={"flex flex-col align-center justify-center w-full h-full pr-0 md:pr-8 leading-none text-left relative"}>
                                    <div className={"py-4"}>
                                        <h1 className={"font-agrandir md:text-3xl lg:text-4xl block"}>{videos[2].video.title}</h1>
                                    </div>
                                    <div className={"py-4"}>
                                        <Link to={`/${locale}/tv/${videos[2].video.slug}`}>
                                            <Button label={"watch now"} type={"underline"} display={"inline"} />
                                        </Link>
                                    </div>
                                    <div className={Styles.pattern} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        )

    }

}

export default FeaturedVideos
import React from "react"
import { Link } from "gatsby"
import _ from "lodash"

import SectionTitle from "../layout/section-title"
import Thumbnail from "./thumbnail"

class ThumbnailGroup extends React.Component {

    render(){

        let groups = _.chunk(this.props.items, 6)

        return(
            <div className={"container mx-auto py-4"}>
                {
                    groups.map((group, index) => {
                        return (
                            <div key={`group-${index}`}>
                                <SectionTitle title={ index === 0 ? this.props.title : ''} position={"center"} />
                                <div className={"flex flex-wrap -mt-12"}>
                                {
                                    group.map((item, i) => {
                                        return (
                                            <div className={"w-full sm:w-full md:w-1/2 lg:w-1/3 px-4 lg:p-4"} key={i}>
                                                <Link to={`${this.props.href}${item.slug}`}>
                                                    <Thumbnail
                                                        title={item.title}
                                                        image={item.image.file.url}
                                                        description={item.short}
                                                    />
                                                </Link>
                                            </div>
                                        )
                                        
                                    })
                                }
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        )
    }

}

export default ThumbnailGroup
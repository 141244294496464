import React from "react"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import { graphql } from "gatsby"
import FeaturedVideos from "../components/home/featured-videos"
import FestivalPlayer from "../components/home/festival"
import SliderCarousel from "../components/carousel/slider"
import PageHeader from "../components/layout/page-header"
import Layout from "../components/layout/layout"
import PageTransition from "../components/transition/page"
import ThumbnailGroup from "../components/thumbnail/thumbnail-group"
import SEO from "../components/seo/seo"
import moment from "moment"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.pageTransition = React.createRef()
  }
  animate() {
    this.pageTransition.current.play()
  }
  render() {
    const { path, data } = this.props
    const locale = "en-AU"
    let locations = []
    data.allFestivals.festivals.forEach((festival, index) => {
      festival.locations.items.forEach((location, i) => {
        locations.push(location)
      })
    })
    locations.sort((a, b) => moment(b.date).isBefore(a.date) ? 1 : -1)
    let news = []
    data.news.items.forEach((item, index) => {
      news.push(item.article)
    })
    return (
      <Layout locale={locale} theme="light" path={path} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
        <SEO
          title={data.seo.title}
          description={data.seo.description}
          keywords={data.seo.keywords && data.seo.keywords.keywords}
          image={data.seo.image.file.url}
        />
        <PageHeader>
          <SliderCarousel slides={data.slider.slides} locale={locale} />
        </PageHeader>
        <FeaturedVideos videos={data.videos.items} locale={locale} />
        <FestivalPlayer video={data.homeVideo.file.url} locations={locations} locale={locale} />
        <ThumbnailGroup title={"latest news"} items={news} href={`/${locale}/articles/`} locale={locale} />
        <TransitionPortal>
          <PageTransition ref={this.pageTransition} />
        </TransitionPortal>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    seo: contentfulSeo(slug: {eq: "home-page"}) {
      title
      description
      keywords {
        keywords
      }
      image {
        file {
          url
        }
      }
    }
    slider: contentfulSlider(slug: { eq: "home-page" }, node_locale: { eq: "en-AU" }) {
      slides {
        ... on ContentfulSlide {
          title
          short
          image {
            file {
              url
            }
          }
          link
          cta
        }
      }
    }
    homeVideo: contentfulAsset(contentful_id: { eq: "w39bNX4xfnoTLqYC9wnJk" }, node_locale: { eq: "en-AU" }) {
      file {
        url
      }
    }
    allFestivals: allContentfulFestival(limit: 50, filter: { node_locale: { eq: "en-AU" } }) {
      festivals: edges {
        locations: node {
          items: locations {
            ... on ContentfulLocation {
              slug
              title
              venue
              date
              festival {
                title
              }
              tile {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
    videos: allContentfulVideo(limit: 3, filter: { node_locale: { eq: "en-AU" }, visible: { eq: true } }) {
      items: edges {
        video: node {
          ... on ContentfulVideo {
            title
            slug
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
    news: allContentfulArticle(limit: 6, filter: { visible: { eq: true }, node_locale: { eq: "en-AU" } }) {
      items: edges {
        article: node {
          ... on ContentfulArticle {
            title
            slug
            short
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage

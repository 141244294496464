import React from "react"
import moment from "moment"
import Swiper from "swiper"
import { Link } from "gatsby"

import styles from "./festival.module.scss"
import RightArrow from "../../images/arrow-small-right.svg"
import LeftArrow from "../../images/arrow-small-left.svg"
import LocationTile from "../festival/location-tile"

class FestivalPlayer extends React.Component {

    constructor(props){
        super(props)
        this.swiper = null
    }

    componentDidMount(){
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            this.swiper = new Swiper ('.location-swiper-container', {
                loop: false,
                breakpoints: {
                    320: {
                        slidesPerView: 3,
                        spaceBetween: 0
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 0
                    },
                    1200:{
                        slidesPerView: 6,
                        spaceBetween: 0,
                    }
                },
                autoplay: {
                    delay: 3000,
                },
                navigation: {
                  nextEl: '.l-button-next',
                  prevEl: '.l-button-prev',
                },
            })
        }

    }

    render(){

        const { video, locations, locale } = this.props

        return(
            <section className={"md:pt-12 md:mt-16"}>
                <div className={"container mx-auto relative lg:mt-20"}>
                    <div className={styles.redWaveBackground}></div>
                    <video muted playsInline autoPlay loop className={"w-full px-4 md:px-12"}>
                        <source src={video} type="video/mp4" />
                    </video>
                    <div className={"container mx-auto -mt-2 md:-mt-4 overflow-x-hidden"}>
                        <div className={"location-swiper-container"}>
                             <div className={`${styles.locationButtonPrev} l-button-next absolute py-4 px-4`}>
                                <img src={LeftArrow} alt="prev" />
                            </div>
                            <div className={`${styles.locationButtonNext} l-button-prev absolute py-4 px-4`}>
                                <img src={RightArrow} alt="next" />
                            </div>
                            <div className={"swiper-wrapper"}>
                                {
                                    locations.map((item,index) => {
                                        return(
                                            <div className={"swiper-slide"} key={`slide-${index}`} >
                                                <Link to={`/${locale}/locations/${item.slug}`}>
                                                    <LocationTile
                                                        title={item.title}
                                                        day={moment.parseZone(item.date).format("ddd")}
                                                        month={moment.parseZone(item.date).format("D MMMM")}
                                                        file={item.tile.file.url}
                                                        align={"center"}
                                                    />
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                                <div className={"swiper-slide"}>
                                    <LocationTile
                                        title={"BRAZIL"}
                                        text={"Coming Soon"}
                                        file={"https://images.ctfassets.net/8ipgrsmep6cq/2ifZJHYaCOz1rn8xEtYfjy/30a24a96904cf7a309cce8f0e2b4191c/Tiles_Event_Waves.svg"}
                                        align={"center"}
                                    />
                                </div>
                                <div className={"swiper-slide"}>
                                    <LocationTile
                                        title={"JAPAN"}
                                        text={"Coming Soon"}
                                        file={"https://images.ctfassets.net/8ipgrsmep6cq/Ix1qg1ShkqnNiTo60yRA0/5aa3c701d42fa8a07ec84003eab5bec7/Tiles_Event_Palm.svg"}
                                        align={"center"}
                                    />
                                </div>
                                <div className={"swiper-slide"}>
                                    <LocationTile
                                        title={"HAWAII"}
                                        text={"Coming Soon"}
                                        file={"https://images.ctfassets.net/8ipgrsmep6cq/0R9SxRKBZmBwAW70fvE7V/19d0ac14220ea688c25d4be51d709554/Tiles_Event_Leaf.svg"}
                                        align={"center"}
                                    />
                                </div>
                                <div className={"swiper-slide"}>
                                    <LocationTile
                                        title={"PORTUGAL"}
                                        text={"Coming Soon"}
                                        file={"https://images.ctfassets.net/8ipgrsmep6cq/5gvm7J0ZO8bJaAj1Tng3qs/ba28e62cc4b912d15c10a98db6c0bcdc/Tiles_Event_Flower.svg"}
                                        align={"center"}
                                    />
                                </div>
                                <div className={"swiper-slide"}>
                                    <LocationTile
                                        title={"FRANCE"}
                                        text={"Coming Soon"}
                                        file={"https://images.ctfassets.net/8ipgrsmep6cq/4jvoIJly5ZOGrOarqt2cQF/48e4a1842426a61cc4f4e08d4ed216fe/Tiles_Event_Fern.svg"}
                                        align={"center"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.halfCircleBackground}></div>
                </div>
            </section>
        )
    }

}

export default FestivalPlayer
